import queryString from 'query-string';

export const getResizedUrl = (
  src: string,
  width?: number,
  height?: number,
): string => {
  const parsed = queryString.parseUrl(src);

  if (width) {
    parsed.query.w = width.toString();
  }

  if (height) {
    parsed.query.h = height.toString();
  }

  return queryString.stringifyUrl(parsed);
};
