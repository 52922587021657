import type { LineItem, Order } from '@commercelayer/sdk';
import type { EntryCollection } from 'contentful';

import type {
  DigitalProduct,
  LineItemType,
  Product,
  ProductBenefitParsed,
  ProductSelectorDetails,
  ProductType,
  ProductTypeIdentifier,
  UpsellProduct,
} from 'productSelection/types/products';
import { PRODUCT_ALIASES } from 'shared/constants/ProductConstants';
import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import { translateProductToStore } from 'shared/infra/commerceLayer/utils';
import { getCatalogEntriesByCountry } from 'shared/infra/contentful';
import type {
  IBundleProduct,
  ICatalogFields,
  IDigitalProduct,
  IListItemFields,
  IProduct,
  IProductBenefitFields,
  IProductSelector,
  IUpsellProduct,
  LOCALE_CODE,
} from 'shared/infra/contentful/contentful';
import type { ListItemParsed } from 'shared/infra/contentful/types';
import { getOrderSubscriptionFromEcomPlatform } from 'shared/infra/storefront/services';
import {
  convertImageToPng,
  forceImgSrcToHttps,
} from 'shared/services/imageUrl';
import type { OrderLineItem } from 'shared/store/order/types';
import { createCFProductId } from 'shared/types/ids';
import { SUMUP_ONE_SKU } from 'src/cart/services/CartOverviewService';
import type { Maybe } from 'types/util';
import { formatCurrencyWithLocale } from 'utils/currency';

import logger from './logger';
import { retrieveGlobalLocale } from './OrderInformationService.globals';

export type Subscription = {
  sku_code: string;
  discount_percentage: number;
  currency_code: string;
  total_amount_cents: number;
  total_amount_float: number;
  tax_amount_cents: number;
  tax_amount_float: number;
  total_amount_with_taxes_and_discounts_cents: number;
  total_amount_with_taxes_and_discounts_float: number;
  discount_cents: number;
  discount_float: number;
  tax_rate: number;
};

export type PaymentInstrument =
  | CreditCardPaymentInstrument
  | SepaPaymentInstrument;

export type CreditCardPaymentInstrument = {
  id: string;
  type: 'credit_card_payment_instruments';
  attributes: {
    scheme:
      | 'AMEX'
      | 'CUP'
      | 'DINERS'
      | 'DISCOVER'
      | 'ELO'
      | 'ELV'
      | 'HIPERCARD'
      | 'JCB'
      | 'MAESTRO'
      | 'MASTERCARD'
      | 'UNKNOWN'
      | 'VISA'
      | 'VISA_ELECTRON'
      | 'VISA_VPAY';
    last4: string;
  };
};

export type SepaPaymentInstrument = {
  id: string;
  type: 'sepa_payment_instruments';
  attributes: {
    masked_iban: string;
  };
};

interface ProductTrackingInfo {
  id: string;
  trackingId: string;
  price: string;
  quantity: number;
}

const DUE_NOW_PRODUCT_TYPES: ProductTypeIdentifier[] = ['hardware', 'service'];

const CONDITIONS_PRODUCT_TYPES: ProductTypeIdentifier[] = [
  'contract_duration',
  'fee_campaign',
  'subscription_discount',
];

const parseContentfulProductBenefit = (
  productBenefit: IProductBenefitFields,
): ProductBenefitParsed => ({
  name: productBenefit?.name || '',
  title: productBenefit?.title || '',
  description: productBenefit?.description || '',
  icon: {
    imageSrc: forceImgSrcToHttps(productBenefit?.icon?.fields?.file?.url || ''),
    imageAltText: productBenefit?.icon?.fields?.description || '',
  },
});

const parseListItem = (item: IListItemFields): ListItemParsed => ({
  name: item?.name || '',
  title: item?.title || null,
  description: item?.description || null,
  icon: {
    imageSrc: forceImgSrcToHttps(item?.icon?.fields?.file?.url || ''),
    imageAltText: item?.icon?.fields?.description || '',
  },
});

const parseProductSelector = (
  productSelector?: IProductSelector,
): ProductSelectorDetails => {
  if (!productSelector) {
    return null;
  }

  return {
    name: productSelector.fields.name || '',
    title: productSelector.fields.title || '',
    badgeText: productSelector.fields.badgeText || '',
    description: productSelector.fields.description || null,
    productBenefits: (productSelector.fields.productBenefits || []).map(
      (item) => parseListItem(item.fields),
    ),
  };
};

export const parseContentfulDigitalProducts = (
  digitalProducts: IDigitalProduct[],
): DigitalProduct[] =>
  digitalProducts.map((digitalProduct) => ({
    ...digitalProduct.fields,
    id: digitalProduct.sys.id,
    slug: digitalProduct.fields.trackingId,
    productContent: {
      imageSrc: convertImageToPng(
        digitalProduct.fields.images[0]?.fields.file.url || '',
      ),
      imageAltText: digitalProduct.fields.images[0]?.fields.description || '',
    },
    images:
      digitalProduct.fields.images?.map((image) => ({
        id: image.sys.id,
        imageSrc: convertImageToPng(image.fields.file.url),
        imageAltText: image.fields.description || '',
      })) || [],
    shouldShowHighlight: digitalProduct.fields.showProductHighlight,
    highlightTitle: digitalProduct.fields.productHighlightTitle || '',
    highlightDescription:
      digitalProduct.fields.productHighlightDescription || '',
    selectProductLabel: digitalProduct.fields.selectProductLabel || '',
    selectedProductLabel: digitalProduct.fields.selectedProductLabel || '',

    productDescription: digitalProduct.fields.productDescription || '',
    faqs: (digitalProduct.fields.faqs || []).map((item) => item.fields),

    productBenefits: (digitalProduct.fields.productBenefits || []).map((pb) =>
      parseContentfulProductBenefit(pb.fields),
    ),
    acceptedCardSchemaLabel:
      digitalProduct.fields.acceptedCardSchemaLabel || '',
    cardSchemasAccepted: (digitalProduct.fields.acceptedCardsSchemas ||
      []) as string[],

    otherPaymentTypesLabel: digitalProduct.fields.otherPaymentTypesLabel || '',
    otherAcceptedPaymentTypes: (digitalProduct.fields
      .otherAcceptedPaymentTypes || []) as string[],

    howItWorks: digitalProduct.fields.howItWorks || null,
    howItWorksHeadline: digitalProduct.fields.howItWorksHeadline || '',
    isFree: digitalProduct.fields.isFree,
    hideProductSummary: digitalProduct.fields.hideProductSummary,
  }));

export const getProductType = (
  product: IProduct | IBundleProduct,
): ProductType | undefined =>
  product.fields.type ? (product.fields.type.fields as ProductType) : undefined;

const isUpsellProduct = (
  product: IBundleProduct | IUpsellProduct | IProduct,
): product is IUpsellProduct =>
  product.sys.contentType.sys.id === 'upsellProduct';

const isBundleProduct = (
  product: IBundleProduct | IUpsellProduct | IProduct,
): product is IBundleProduct =>
  product.sys.contentType.sys.id === 'bundleProduct';

const isProduct = (
  product: IBundleProduct | IUpsellProduct | IProduct,
): product is IProduct => product.sys.contentType.sys.id === 'product';

export const getLineItemType = (
  product: IProduct | IBundleProduct | IUpsellProduct,
): LineItemType => {
  if (isBundleProduct(product)) {
    return 'bundle';
  }
  if (isProduct(product)) {
    return product.fields.bundleCode ? 'bundle' : 'sku';
  }
  return 'sku';
};

export const isSubscriptionProduct = (product: Product): boolean =>
  product.productType?.identifier === 'license';

export const isCondition = (product: Product): boolean =>
  CONDITIONS_PRODUCT_TYPES.includes(product.productType.identifier);

export const isFeeCondition = (product: Product): boolean =>
  product.productType.identifier === 'fee_campaign';

export const isDueNowProduct = (product: Product): boolean =>
  DUE_NOW_PRODUCT_TYPES.includes(product.productType.identifier);

export const getCode = (
  product: IBundleProduct | IProduct | IUpsellProduct,
): Maybe<string> => {
  if (isBundleProduct(product)) {
    return product.fields.sku;
  }

  if (isUpsellProduct(product)) {
    return product.fields.sku_code;
  }

  if (isProduct(product)) {
    const { sku_code: skuCode, bundleCode } = product.fields;
    if (skuCode && bundleCode) {
      logger
        .withContext({
          tags: {
            skuCode,
            bundleCode,
            productId: product.sys.id,
          },
        })
        .error(new Error('Product has both sku_code and bundleCode'));

      return null;
    }
    if (!skuCode && !bundleCode) {
      logger
        .withContext({ tags: { productId: product.sys.id } })
        .error(new Error('Product has neither sku_code nor bundleCode'));

      return null;
    }

    return skuCode || bundleCode;
  }

  throw new Error('Unable to find SKU');
};

const parseUpsellProduct = (
  upsellProduct?: IUpsellProduct,
): Maybe<UpsellProduct> => {
  if (!upsellProduct) {
    return null;
  }

  const code = getCode(upsellProduct);

  if (!code) {
    return null;
  }

  return {
    id: createCFProductId(upsellProduct.sys.id),
    lineItemType: getLineItemType(upsellProduct),
    code,
    name: upsellProduct.fields.name,
    title: upsellProduct.fields.title || '',
    trackingId: upsellProduct.fields.trackingId || '',
    shortDescription: upsellProduct.fields.shortDescription || null,
    descriptionCTA: upsellProduct.fields.showDescriptionCTA || '',
    description: upsellProduct.fields.description || null,
    descriptionModal: {
      title: upsellProduct.fields.pdpModalTitle || '',
      description: upsellProduct.fields.pdpModalDescription || null,
      requirements: {
        title: upsellProduct.fields.pdpModalRequirementsTitle || '',
        items: (upsellProduct.fields.upsellProductRequirements || []).map(
          (item) => parseListItem(item.fields),
        ),
      },
    },
    productSelector:
      parseProductSelector(upsellProduct.fields.productSelector) || null,
    selectProductCtaLabel: upsellProduct.fields.selectProductCtaLabel || '',
    transactionFee: upsellProduct.fields.transactionFee || '',
  };
};

export const parseContentfulUpsellProducts = (
  contentfulUpsellProducts: IUpsellProduct[],
): UpsellProduct[] =>
  contentfulUpsellProducts.flatMap((upsellProduct) => {
    const parsed = parseUpsellProduct(upsellProduct);
    return parsed ? [parsed] : [];
  });

const parseProduct = (product: IProduct | IBundleProduct): Maybe<Product> => {
  const code = getCode(product);
  if (!code) {
    return null;
  }
  return {
    id: createCFProductId(product.sys.id),
    code,
    productType: getProductType(product),
    lineItemType: getLineItemType(product),
    name: product.fields.title,
    trackingId: product.fields.trackingId,
    slug: product.fields.trackingId,
    productContent: {
      imageSrc: convertImageToPng(product.fields.images[0]?.fields.file.url),
      imageAltText: product.fields.images[0]?.fields.description || '',
    },
    images: product.fields.images.map((image) => ({
      id: image.sys.id,
      imageSrc: convertImageToPng(image.fields.file.url),
      imageAltText: image.fields.description || '',
    })),
    bulletPoints: product.fields.bulletPoints,
    shouldShowHighlight: product.fields.shouldShowHighlight,
    highlightTitle: product.fields.highlightTitle || '',
    highlightDescription: product.fields.highlightDescription || '',
    highlightIcon: product.fields.highlightIcon,
    productBenefits: (product.fields.productBenefits || []).map((pb) =>
      parseContentfulProductBenefit(pb.fields),
    ),
    acceptedCardSchemaLabel: product.fields.acceptedCardSchemaLabel || '',
    cardSchemasAccepted: (product.fields.acceptedCardsSchemas ||
      []) as string[],
    otherPaymentTypesLabel: product.fields.otherPaymentTypesLabel || '',
    otherAcceptedPaymentTypes: (product.fields.otherAcceptedPaymentTypes ||
      []) as string[],
    deliveryTime: product.fields.deliveryTime || null,
    summaryDescription: product.fields.summaryDescription || null,
    moneyBackGuarantee: product.fields.moneyBackGuarantee || '',
    productDescription: product.fields.productDescription || '',
    shippingFee: product.fields.shippingFee || '',
    faqs: (product.fields.faqs || []).map((item) => item.fields),
    numberOfInstallments: product.fields.installments || 1,
    installmentsFeeInfo: product.fields.installmentsFeeInfo || '',
    fullPriceInfo: product.fields.fullPriceInfo || '',
    selectProductLabel: product.fields.selectProductLabel || '',
    selectedProductLabel: product.fields.selectedProductLabel || '',
    shortBenefits: product.fields.shortBenefits || '',
    businessAccount: parseUpsellProduct(product.fields.businessAccount) || null,
    productSelector:
      parseProductSelector(product.fields.productSelector) || null,
    transactionFee: product.fields.transactionFee || '',
    plan: 'plan' in product.fields ? product.fields.plan || null : null,
  };
};

export const parseContentfulProducts = (
  contentfulProducts: (IProduct | IBundleProduct)[],
): Product[] =>
  contentfulProducts.flatMap((product) => {
    const parsed = parseProduct(product);
    return parsed ? [parsed] : [];
  });

export const getProductByTrackingId = <T extends Product | DigitalProduct>(
  products: T[],
  trackingId: string,
): T | undefined =>
  products?.find(
    (product) => 'trackingId' in product && product.trackingId === trackingId,
  );

export const reorderCatalogByQuery = <T extends Product | DigitalProduct>(
  products: T[],
  productsOnQuery: string[],
): T[] => {
  if (productsOnQuery) {
    return productsOnQuery.reduce<T[]>(
      (acc, current: keyof typeof PRODUCT_ALIASES) => {
        /**
       * Check for available aliases for existing products. This is only
         necessary while the old shop is still supported. The goal is
         to deprecate this logic and remove aliases as soon as the
         storefront becomes the default platform.
       */
        const productTrackingId = PRODUCT_ALIASES[current] || current;

        const product = getProductByTrackingId(products, productTrackingId);

        return product ? [...acc, product] : acc;
      },
      [],
    );
  }
  return products;
};

export const filterProductCatalog = (
  products: Product[],
  digitalProducts: DigitalProduct[],
  productsOnQuery: string[],
): {
  products: Product[];
  digitalProducts: DigitalProduct[];
} => {
  const filteredProducts = reorderCatalogByQuery(products, productsOnQuery);
  const filteredDigitalProducts = reorderCatalogByQuery(
    digitalProducts,
    productsOnQuery,
  );

  if (filteredProducts.length > 0 || filteredDigitalProducts.length > 0) {
    return {
      products: filteredProducts,
      digitalProducts: filteredDigitalProducts,
    };
  }

  return {
    products,
    digitalProducts,
  };
};

export const joinCatalogProductsForCountry = (
  countryCatalogEntries: EntryCollection<ICatalogFields>,
): IProduct[] => {
  /**
   * We need a list of all products available on a country.
   * uniqueProductsById is an objects of Contentful product information by id,
   * without duplicates.
   */
  const uniqueProductsById = countryCatalogEntries.items.reduce(
    (productsMap, current) => {
      const catalogProductsMap = current.fields.products.reduce(
        (productsById, currentProduct) => ({
          ...productsById,
          [currentProduct.sys.id]: currentProduct,
        }),
        {},
      );

      return {
        ...productsMap,
        ...catalogProductsMap,
      };
    },
    {},
  );

  return Object.values(uniqueProductsById);
};

export const findProductByCode = (products: Product[], code: string): Product =>
  products.find((p) => p.code === code);

export const combineProductsInfo = (
  products: Product[],
  lineItems: OrderLineItem[],
  subscription: Subscription | null,
  locale = retrieveGlobalLocale(),
): { products: Product[]; outOfCatalogLineItems: OrderLineItem[] } => {
  const combinedProducts: Product[] = [];
  const outOfCatalogLineItems: OrderLineItem[] = [];

  lineItems.forEach((lineItem) => {
    const product = findProductByCode(products, lineItem.code);

    if (!product) {
      outOfCatalogLineItems.push(lineItem);
      return;
    }

    const combinedProduct: Product = {
      id: lineItem.id,
      trackingId: product.trackingId,
      name: product.name,
      lineItemType: product.lineItemType,
      productType: product.productType,
      productContent: product.productContent,
      productDescription: product.productDescription,
      code: product.code,
      discountCents: lineItem.discountCents,
      discountRate: lineItem.discountRate,
      quantity: lineItem.quantity,
      reference: lineItem.reference,
      slug: product.slug,
      formattedTotalAmount: formatCurrencyWithLocale(
        lineItem.amountFloat,
        locale,
      ),
      formattedUnitAmount: formatCurrencyWithLocale(
        lineItem.unitAmountFloat,
        locale,
      ),
      shortBenefits: product.shortBenefits || '',
      amountFloat: lineItem.amountFloat,
      numberOfInstallments: product.numberOfInstallments,
      businessAccount: product.businessAccount,
      totalAmountFloat: lineItem.totalAmountFloat,
      discountFloat: lineItem.discountFloat,
      ...(isSubscriptionProduct(product) ? { subscription } : {}),
      plan: product.plan,
    };

    combinedProducts.push(combinedProduct);
  });

  return {
    products: combinedProducts,
    outOfCatalogLineItems,
  };
};

export const getLineItemsFromOrder = async (
  order: Order,
  locale: LOCALE_CODE,
): Promise<{
  products: Product[];
  largestInstallmentInCart: number;
  hasBusinessAccount: boolean;
}> => {
  const allCountryCatalogEntries = await getCatalogEntriesByCountry(
    getCountryCodeFromISOLocale(locale),
    locale,
  );

  const productEntriesForCountry = joinCatalogProductsForCountry(
    allCountryCatalogEntries,
  );

  const productsContentful = parseContentfulProducts(productEntriesForCountry);

  const productsInCart = order.line_items
    ?.filter((li) => li.item_type === 'skus' || li.item_type === 'bundles')
    .map((li) => translateProductToStore(li));

  const productReferencesInCart = productsInCart.map((p) => p.reference);

  const productInstallments = productsContentful
    .filter((p) => productReferencesInCart.includes(createCFProductId(p.id)))
    .map((p) => p.numberOfInstallments);

  const largestInstallmentInCart = Math.max(...productInstallments);

  let subscription: Subscription | null = null;

  if (hasOrderSubscriptionInCart(productsInCart, productsContentful)) {
    try {
      subscription = await getOrderSubscriptionFromEcomPlatform(order.id);
    } catch (e) {
      logger.error(e, 'Error fetching subscription from order');
    }
  }

  const { products } = combineProductsInfo(
    productsContentful,
    productsInCart,
    subscription,
    locale,
  );

  const hasBusinessAccount = !!productsInCart.find(
    (product) =>
      !!products.find((p) => p?.businessAccount?.code === product.code),
  );

  return { products, largestInstallmentInCart, hasBusinessAccount };
};

export function getConditionProducts(products: Product[]): Product[] {
  return products.filter(isCondition);
}

export function getDueNowProducts(products: Product[]): Product[] {
  return products.filter(isDueNowProduct);
}

const hasProductOfProductTypeInCart = (
  productTypeIdentifier: ProductTypeIdentifier,
  productsInCart: OrderLineItem[],
  catalogProducts: Product[],
): boolean => {
  const skusInCart = new Set(productsInCart.map((product) => product.code));

  return catalogProducts.some(
    (product) =>
      product.productType?.identifier === productTypeIdentifier &&
      skusInCart.has(product.code),
  );
};

export function hasShippableProductsInCart(
  productsInCart: OrderLineItem[],
  catalogProductDetails: Product[],
): boolean {
  return hasProductOfProductTypeInCart(
    'hardware',
    productsInCart,
    catalogProductDetails,
  );
}

export function hasOrderSubscriptionInCart(
  productsInCart: OrderLineItem[],
  catalogProductDetails: Product[],
): boolean {
  return hasProductOfProductTypeInCart(
    'license',
    productsInCart.filter((v) => v.code !== SUMUP_ONE_SKU),
    catalogProductDetails,
  );
}

export const getProductsTrackingInfo = (
  productsContent: Product[],
  lineItems: Pick<
    LineItem,
    'id' | 'total_amount_float' | 'quantity' | 'sku_code' | 'bundle_code'
  >[],
): ProductTrackingInfo[] =>
  lineItems.reduce<ProductTrackingInfo[]>((acc, lineItem) => {
    const productContent = findProductByCode(
      productsContent,
      lineItem.sku_code || lineItem.bundle_code,
    );

    if (!productContent) {
      return acc;
    }

    return [
      ...acc,
      {
        id: lineItem.id,
        trackingId: productContent?.trackingId,
        price: lineItem.total_amount_float.toString(),
        quantity: lineItem.quantity,
      },
    ];
  }, []);

export const getLicenseProductType = (
  products: Product[],
): ProductType | undefined => {
  const licenseProduct = products.find(
    (product) => product.productType?.identifier === 'license',
  );

  return licenseProduct?.productType;
};
