import type { Order } from '@commercelayer/sdk';

import type { AttributionData } from 'checkout/types/checkout';
import type { PaymentPlan } from 'shared/services/PaymentPlanService';
import type { Subscription } from 'shared/services/ProductService';
import type { Maybe } from 'types/util';
import { formatCurrencyWithLocale } from 'utils/currency';

import {
  getOrderPaymentPlan,
  getOrderSubscription,
  getOrderSubscriptionFromEcomPlatform,
  patchSubscriptionTaxId,
  postChargePaymentOnFile,
  type PostChargeResponse,
  postUnplaceOrder,
  postValidateOrder,
  putPlaceOrder,
  type ValidationResponse,
} from '../services';

export const placeOrder = (
  orderId: Order['id'],
  attributionData: AttributionData,
): Promise<unknown> => putPlaceOrder(orderId, attributionData);

export const unplaceOrder = (orderId: Order['id']): Promise<Order> =>
  postUnplaceOrder(orderId);

export const shouldUnplaceOrder = (
  orderStatus: Order['status'],
  paymentReference: Maybe<string>,
): boolean =>
  orderStatus === 'placing' && paymentReference !== 'tokenized_sepa';

export const shouldResetOrder = (
  orderStatus: Order['status'],
  paymentReference: Maybe<string>,
): boolean =>
  orderStatus === 'placing' && paymentReference === 'tokenized_sepa';

export const validateOrder = (
  orderId: string,
  merchantCode: string,
): Promise<ValidationResponse> => postValidateOrder(orderId, merchantCode);

export const getSubscription = (
  orderId: string,
): Promise<Subscription | null> => getOrderSubscription(orderId);

export const getTotalSubscriptionPriceFormatted = async (
  queryId: string,
  locale: string,
): Promise<string> => {
  let totalSubscriptionPriceFormatted: string;

  try {
    const subscription = await getOrderSubscriptionFromEcomPlatform(queryId);

    totalSubscriptionPriceFormatted = formatCurrencyWithLocale(
      subscription.total_amount_float,
      locale,
    );
  } catch (err) {
    totalSubscriptionPriceFormatted = '';
  }

  return totalSubscriptionPriceFormatted;
};

export const updateSubscriptionTaxId = async (
  orderId: string,
  taxId: string,
): Promise<Subscription> => patchSubscriptionTaxId(orderId, taxId);

export const getPaymentPlan = (orderId: string): Promise<PaymentPlan | null> =>
  getOrderPaymentPlan(orderId);

export const chargePaymentOnFile = (
  merchantCode: string,
  orderId: string,
): Promise<PostChargeResponse> =>
  postChargePaymentOnFile(merchantCode, orderId);
