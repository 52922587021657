/**
 * These mappings are only necessary while we're migrating links from
 * the legacy shop to the new one. Once the migration is over, these mappings
 * should be deprecated and removed.
 */

export const PRODUCT_ALIASES = {
  'chipsig': 'card_reader.chip',
  'air': 'card_reader.air',
  'air_bundle': 'card_reader.air_bundle',
  'pinplus': 'card_reader.pin_lite',
  'pinlite': 'card_reader.pin_lite',
  'top': 'card_reader.pin_contactless',
  'super': 'card_reader.super',
  'air_lite': 'card_reader.air_lite',
  '3g': 'card_reader.bp55',
  'total': 'card_reader.total',
  'D195': 'card_reader.D195',
  'topnfc': 'card_reader.pin_contactless',
  'top-old': 'card_reader.pin_lite',
  'card_reader.air_bundle_plus_de_exp': 'card_reader.air_bundle',
  'card_reader.air_plus_de_exp': 'card_reader.air',
  'air_bundle_plus_de_exp': 'card_reader.air_bundle',
  'air_plus_de_exp': 'card_reader.air',
  'solo_bundle_cradle': 'card_reader.solo_bundle_cradle',
} as const;
