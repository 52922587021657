import { action } from 'typesafe-actions';

import {
  AddressTypes,
  type SaveAddressesAction,
  type SaveAddressesFailureAction,
  type SaveAddressesSuccessAction,
  type StoreAddressAction,
} from 'shared/store/address/types';

export const storeAddressAction = (
  payload: StoreAddressAction['payload'],
): StoreAddressAction => action(AddressTypes.STORE_ADDRESSES, payload);

export const saveAddressesAction = (
  payload: SaveAddressesAction['payload'],
): SaveAddressesAction => action(AddressTypes.SAVE_ADDRESSES_REQUEST, payload);

export const saveAddressesSuccessAction = (
  payload: SaveAddressesSuccessAction['payload'],
): SaveAddressesSuccessAction =>
  action(AddressTypes.SAVE_ADDRESSES_SUCCESS, payload);

export const saveAddressesFailureAction = (
  error: SaveAddressesFailureAction['payload']['error'],
): SaveAddressesFailureAction =>
  action(AddressTypes.SAVE_ADDRESSES_FAILURE, { error });
